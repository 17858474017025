/* src/index.css */

/* Tailwind CSS imports */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom variables */
:root {
  --primary-color: #0d9488; /* Teal 600 */
  --primary-light: #14B8A6; /* Teal 500 */
  --primary-dark: #0F766E; /* Teal 700 */
  --secondary-color: #22C55E; /* Green 500 */
  --secondary-light: #4ADE80; /* Green 400 */
  --secondary-dark: #16A34A; /* Green 600 */
  --text-color: #1F2937; /* Gray 800 */
  --background-color: #F9FAFB; /* Gray 50 */
}

/* Base styles */
body {
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}

/* Custom utility classes */
.text-shadow {
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

/* Custom animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

/* Accessibility improvements */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Custom styles for specific components */
.btn-primary {
  @apply bg-teal-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-teal-700 transition-colors;
}

.btn-secondary {
  @apply bg-green-500 text-white font-semibold px-6 py-3 rounded-md hover:bg-green-600 transition-colors;
}

.card {
  @apply bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg;
}

/* Responsive typography */
h1 {
  @apply text-4xl md:text-5xl font-bold;
}

h2 {
  @apply text-3xl font-bold;
}

h3 {
  @apply text-2xl font-semibold;
}

/* Form styles */
input, textarea {
  @apply w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent;
}

/* Print styles */
@media print {
  .no-print {
    display: none;
  }
}

/* Custom gradients */
.bg-gradient-primary {
  @apply bg-gradient-to-r from-teal-600 to-teal-400;
}

.bg-gradient-secondary {
  @apply bg-gradient-to-r from-green-500 to-green-400;
}

.masonry-layout {
  column-count: 2;
  column-gap: 1rem;
}

.masonry-layout img {
  width: 100%;
  display: block;
  margin-bottom: 1rem;
  break-inside: avoid;
}

.quill .ql-editor {
  font-size: 18px; /* Adjust this value to increase or decrease the font size */
  line-height: 1.6; /* Optional: adjust line height for better readability */
}